
import request from '@/utils/request';



class HouseApi {
    

    list = (data) => {

        return request({
            url: '/adm/house/house/houses_list',
            method: 'post',
            data
        });	
    }



    edit = (data) => {

        return request({
            url: '/adm/house/house/house_edit',
            method: 'post',
            data
        });	
    }


    type_config = () => {
        return request({
            url: '/adm/house/house/house_config',
            method: 'post',
            
        });	
    }

}



let _api = null

const getHouseApi = () => {
    if (!_api) {
        _api = new HouseApi();
    }
    return _api;
}

export { getHouseApi };


